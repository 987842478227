import React, { useState } from 'react';
import cln from 'classnames';

import styles from './InputBox.module.css';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';

type Props = {
  leftImg: string;
  rightImg: string;
  input: DefaultInputType;
  label: string;
  setInput: (s: DefaultInputType) => void;
  disabled?: boolean;
  type?: string;
  handleRight?: () => void;
  validation?: (value: string) => DefaultInputType;
};

const InputBox = (props: Props) => {
  const {
    label = 'Password',
    leftImg = '/icons-elon/landings/olymp/key.svg',
    rightImg = '/icons-elon/landings/olymp/eye.svg',
    input,
    setInput,
    disabled = false,
    type,
    handleRight,
    validation,
  } = props;

  const onChangeField = ({ target }: any) => {
    const { value } = target;

    if (validation) {
      const res = validation(value);

      setInput(res);

      return;
    }

    setInput({ value, errorText: '' });
  };

  return (
    <div
      className={cln(styles.wrapperPasswordBox, {
        [styles.error]: input.errorText,
      })}
    >
      {leftImg && <img src={leftImg} className={styles.key} />}

      <div className={styles.inputWrapper}>
        <input
          placeholder=""
          value={input.value}
          className={cln({ [styles.error]: input.errorText })}
          disabled={disabled}
          type={type}
          onChange={onChangeField}
        />

        <div
          className={cln(styles.label, { [styles.up]: Boolean(input.value) })}
        >
          {label}
        </div>
      </div>

      {rightImg && (
        <img src={rightImg} className={styles.eye} onClick={handleRight} />
      )}
    </div>
  );
};

export default InputBox;
