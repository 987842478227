import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import cln from 'classnames';
import {
  RegistrationSubType,
  ERequestIds,
} from 'decryption_protocol/dist/lib/binary/types';
import { DefaultInputType } from 'decryption_component-kit/dist/lib/components/ui/Inputs/BaseInput/types';
import { useTransportControllers, useAuth } from 'decryption_protocol_hooks';

import { Text } from 'decryption_component-kit';
import BonusContent from '../BonusContent';
import ComponentKitTabs from 'components/ui/KitComponents/Tab';
import PhoneBox from './components/PhoneBox';
import PasswordBox from './components/PasswordBox';
import CurrencyBox from './components/CurrencyBox';
import InputBox from './components/InputBox';

import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';

import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import {
  getActiveCountryIdSelector,
  getActiveCurrencyIdSelector,
  setRegisteredLoginAction,
} from 'stores/forms/signUp/selectors';
import { getRegistrationData } from 'utils/transport/main';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { validateEmailValue } from 'utils/validation';

import { ERouteNames } from 'Routes/types';
import { ERegTab } from './types';

import styles from './RegistrationModal.module.css';

const RegistrationModal = ({
  openBonusModal,
  bonusInfo,
}: {
  bonusInfo: any;
  openBonusModal?: (e: any) => void;
}) => {
  const { sendCommand } = useTransportControllers();

  const [activeTab, setActiveTab] = useState<ERegTab>(ERegTab.Email);

  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isSelected, setSelectedStatus] = useState(true);

  const [phone, setPhone] = useState('');

  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAuth = useAuth({ isConnectedApp });

  const currencyId = useZustandRegistrationStore(getActiveCurrencyIdSelector);

  const countryId = useZustandRegistrationStore(getActiveCountryIdSelector);

  const setRegisteredLogin = useZustandRegistrationStore(
    setRegisteredLoginAction
  );

  const toggleState = () => {
    setSelectedStatus(!isSelected);
  };

  const handleSubmit = () => {
    if (!currencyId) {
      return;
    }

    const isPhone = activeTab === ERegTab.Phone;

    if (isPhone) {
      if (!phone) {
        return;
      }
      const sendData = {
        ...getRegistrationData({
          currencyId,
          countryId: countryId || 123,
          registrationType: RegistrationSubType.PhoneWithoutCode,
          promoCode: '',

          countryName: '', // TODO back not validate this value
        }),
        letter: 0,
        login: phone,
        email: '',
        password,
        sms: '',
        requestId: ERequestIds.Registration,
      };

      setRegisteredLogin(phone);

      console.log('🚀 ~ handleSubmit ~ sendData:', sendData);
      sendCommand('08x', sendData, true);

      return;
    }

    const sendData = getRegistrationData({
      registrationType: RegistrationSubType.Email, // 06x - for EMail only
      countryId: 0,
      currencyId,
      promoCode: '',

      countryName: '', // TODO back not validate this value
    });

    setRegisteredLogin(email.value);

    sendCommand(
      '06x',
      {
        ...sendData,
        email: email.value,
        password,
        letters: 0, // mock
        promoCode: '',
      },
      true
    );
  };

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    navigate(ERouteNames.CasinoHome);
  }, [isAuth]);

  const mainField =
    activeTab === ERegTab.Email ? email : { value: phone, errorText: '' };

  return (
    <>
      <div className={styles.bg} />

      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <Text idT="olimpNew.joinNow" />
        </div>

        {/* <div className={styles.modalContent}>
          <label>
            <BonusContent
              toggleChooseBonusModal={openBonusModal}
              {...bonusInfo}
              isActive
            />
          </label>
        </div> */}

        <div className={styles.formContent}>
          <ComponentKitTabs
            activeValue={activeTab}
            data={[
              { label: 'Phone', value: ERegTab.Phone },
              { label: 'Email', value: ERegTab.Email },
            ]}
            onChange={setActiveTab}
            type="underline"
            classes={styles.tabs}
          />

          <div>
            {activeTab === ERegTab.Phone ? (
              <PhoneBox onChange={setPhone} />
            ) : (
              <InputBox
                leftImg="/icons-elon/landings/olymp/email.svg"
                rightImg=""
                setInput={setEmail}
                validation={validateEmailValue}
                input={email}
                label="Email"
              />
            )}

            <PasswordBox onChange={setPassword} />

            <CurrencyBox />

            <div
              className={cln(styles.btn, {
                [styles.disable]:
                  !mainField.value ||
                  mainField.errorText ||
                  !password ||
                  !currencyId ||
                  !isSelected,
              })}
              onClick={handleSubmit}
            >
              <Text idT="olimpNew.joinNow" />
            </div>

            {/* <div className={styles.signIn}>
              T.Account already exists? <span>Log in</span>
            </div> */}

            <div className={styles.signIn} onClick={toggleState}>
              <div className={styles.box}>
                {isSelected && (
                  <img
                    src="/icons-elon/landings/olymp/check.svg"
                    className={styles.checkboxImg}
                  />
                )}
              </div>
              <div className={styles.terms}>
                <Text idT="olimpNew.terms" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationModal;
