import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import CasinoHome from 'pages/Casino/Home';
import CasinoGamePage from 'pages/Casino/CasinoGame';
import CasinoProviderGames from 'pages/Casino/CasinoProviderGames';
import CasinoCategoryGames from 'pages/Casino/CasinoCategoryGames';

import MobileLayout from 'components/Layouts/MobileLayout';
import HOCProtectedRoute from 'components/HOCs/HOCProtectedRoute';
import HOCBettingStartSubscribe from 'components/HOCs/HOCBettingStartSubscribe';
import DesktopLayout from 'components/Layouts/DesktopLayout';
import CircularLoader from 'components/ui/Loaders/Circular';

const Battles = lazy(() => import('pages/Promotions/PromotionBattles/Battles'));
const PromoCode = lazy(() => import('pages/Profile/PromoCode'));
const HomePromotions = lazy(() => import('pages/Promotions/HomePromotions'));
const BattleProviders = lazy(
  () => import('pages/Promotions/PromotionBattles/BattleProviders')
);
const VipClubRules = lazy(
  () =>
    import('components/Alerts/Modal/components/Bonuses/components/VipClubRules')
);
const RankSystem = lazy(() => import('pages/Promotions/RankSystem'));
const BonusOnDeposit = lazy(() => import('pages/Promotions/BonusOnDeposit'));
const FAQ = lazy(() => import('pages/Promotions/FAQ'));
const Applications = lazy(() => import('pages/Promotions/Applications'));
const PromotionMonthly = lazy(
  () => import('pages/Promotions/PromotionMonthly')
);

const ProfileAccount = lazy(() => import('pages/Profile/Account'));
const Transactions = lazy(() => import('pages/Profile/Transactions'));
const HistoryGames = lazy(() => import('pages/Profile/HistoryGames'));
const ReferralProgram = lazy(() => import('pages/Profile/ReferralProgram'));
const HistoryBets = lazy(() => import('pages/Profile/HistoryBets'));

// Lottery
const Lottery = lazy(() => import('pages/Lottery'));

// Betting
const BettingHome = lazy(() => import('pages/Betting/BettingHome'));
const BettingPrematch = lazy(() => import('pages/Betting/BettingPrematch'));
const BettingEventDetails = lazy(
  () => import('pages/Betting/BettingEventDetails')
);
const BettingLive = lazy(() => import('pages/Betting/BettingLive'));
const BettingTournament = lazy(() => import('pages/Betting/BettingTournament'));

// Frame betting
const FrameBettingPage = lazy(() => import('pages/Betting/FrameBettingPage'));

// Landings
const LandingSlots = lazy(() => import('pages/Landings/Slots'));
const LandingAviatrix = lazy(() => import('pages/Landings/Aviatrix'));
const LandingLottery = lazy(() => import('pages/Landings/Lottery'));
const OlimpusNew = lazy(() => import('pages/Landings/OlimpusNew'));
const WheelLandingTwo = lazy(
  () => import('pages/Landings/Wheels/WheelLandingTwo')
);
const WheelLandingRoulette = lazy(
  () => import('pages/Landings/Wheels/WheelRoulette')
);
const PlayMarket = lazy(() => import('pages/Landings/PlayMarket'));
const Buffalo = lazy(() => import('pages/Landings/Wheels/Buffalo'));
const SweetBonanza = lazy(() => import('pages/Landings/Wheels/SweetBonanza'));
const OlympusLanding = lazy(() => import('pages/Landings/Wheels/Olympus'));
const WheelLanding = lazy(() => import('pages/Landings/Wheels/WheelLanding'));
const GreatRhinoLanding = lazy(
  () => import('pages/Landings/Wheels/GreatRhino')
);
const BonusOnDepositLanding = lazy(
  () => import('pages/Landings/BonusOnDeposit')
);
const LandingRouletteBlack = lazy(
  () => import('pages/Landings/Wheels/FSWin/components/LandingRouletteBlack')
);
const LandingTesla = lazy(() => import('pages/Landings/LandingTesla'));
const LandingMoneyComing = lazy(
  () => import('pages/Landings/Wheels/MoneyComing')
);

const CasinoProviders = lazy(() => import('pages/Casino/CasinoProviders'));

// Utils
const WebConfigurations = lazy(() => import('pages/Utils/WebConfigurations'));

import { ERouteNames } from './types';
import { MONTHLY_BONUS_AVAILABLE } from 'constants/marketingTags';

import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import LandingOlymp from 'pages/Landings/Olympus';

const InitialRoutes = () => {
  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  return (
    <Suspense
      fallback={
        <div className="full center">
          <CircularLoader />
        </div>
      }
    >
      <Routes>
        <Route
          // path={ERouteNames.Home}
          element={isMobile ? <MobileLayout /> : <DesktopLayout />}
        >
          <Route path={ERouteNames.CasinoHome} element={<CasinoHome />} />

          <Route path={ERouteNames.CasinoGame} element={<CasinoGamePage />} />

          <Route
            path={ERouteNames.CasinoCategories}
            element={<CasinoCategoryGames />}
          />

          <Route
            path={ERouteNames.CasinoProviderGames}
            element={<CasinoProviderGames />}
          />
          <Route
            path={ERouteNames.CasinoProviders}
            element={<CasinoProviders />}
          />

          <Route path={ERouteNames.Promotions}>
            <Route path={ERouteNames.Promotions} element={<HomePromotions />} />

            <Route
              path={ERouteNames.PromotionsBonusOnDeposit}
              element={<BonusOnDeposit />}
            />

            <Route path={ERouteNames.FAQ} element={<FAQ />} />

            <Route path={ERouteNames.Applications} element={<Applications />} />

            <Route path={ERouteNames.Battles} element={<Battles />} />

            <Route
              path={ERouteNames.ProviderBattles}
              element={<BattleProviders />}
            />

            <Route
              path={ERouteNames.PromotionsVipClub}
              element={<VipClubRules />}
            />
            <Route
              path={ERouteNames.PromotionsRankedSystem}
              element={<RankSystem />}
            />
          </Route>

          <Route element={<HOCBettingStartSubscribe />}>
            <Route
              path={ERouteNames.FrameBetting}
              element={<FrameBettingPage />}
            />

            <Route
              path={ERouteNames.BettingEventDetailsPrematch}
              element={<BettingEventDetails isPrematch />}
            />
            <Route
              path={ERouteNames.BettingEventDetailsLive}
              element={<BettingEventDetails isPrematch={false} />}
            />

            <Route path={ERouteNames.BettingHome} element={<BettingHome />} />

            <Route
              path={ERouteNames.BettingPrematch}
              element={<BettingPrematch />}
            />

            <Route path={ERouteNames.BettingLive} element={<BettingLive />} />

            <Route
              path={ERouteNames.BettingTournament}
              element={<BettingTournament />}
            />
          </Route>

          <Route element={<HOCProtectedRoute />}>
            {MONTHLY_BONUS_AVAILABLE && (
              <Route
                path={ERouteNames.PromotionMonthly}
                element={<PromotionMonthly />}
              />
            )}

            <Route
              path={ERouteNames.ProfileAccount}
              element={<ProfileAccount />}
            />
            <Route path={ERouteNames.PromoCode} element={<PromoCode />} />

            <Route
              path={ERouteNames.ProfileTransactions}
              element={<Transactions />}
            />

            <Route
              path={ERouteNames.ProfileHistoryBets}
              element={<HistoryBets />}
            />
            <Route
              path={ERouteNames.ProfileGameHistory}
              element={<HistoryGames />}
            />
            <Route
              path={ERouteNames.ReferralProgram}
              element={<ReferralProgram />}
            />
          </Route>

          <Route path={ERouteNames.Lottery} element={<Lottery />} />

          {/* Landings */}
          <Route path={ERouteNames.LandingSlots} element={<LandingSlots />} />

          <Route
            path={ERouteNames.LandingAviatrix}
            element={<LandingAviatrix />}
          />
          <Route
            path={ERouteNames.LandingLottery}
            element={<LandingLottery />}
          />
          <Route
            path={ERouteNames.BonusOnDepositLanding}
            element={<BonusOnDepositLanding />}
          />
          <Route path={ERouteNames.WheelLanding} element={<WheelLanding />} />
          <Route
            path={ERouteNames.WheelLandingFourth}
            element={<WheelLanding onlyReg />}
          />
          <Route
            path={ERouteNames.WheelLandingThree}
            element={<WheelLanding olympusFlow />}
          />

          <Route
            path={ERouteNames.WheelLandingTwo}
            element={<WheelLandingTwo />}
          />
          <Route
            path={ERouteNames.WheelRoulette}
            element={<WheelLandingRoulette />}
          />
          <Route path={ERouteNames.PlayMarket} element={<PlayMarket />} />

          <Route path={ERouteNames.Buffalo} element={<Buffalo />} />

          <Route
            path={ERouteNames.WheelLandingWithGirls}
            element={<WheelLandingTwo />}
          />

          <Route path={ERouteNames.SweetBonanza} element={<SweetBonanza />} />

          <Route path={ERouteNames.Olympus} element={<OlympusLanding />} />

          <Route
            path={ERouteNames.OlympusTwo}
            element={<OlympusLanding withPhoneSingUp />}
          />
          <Route
            path={ERouteNames.OlympusNewLanding}
            element={<OlimpusNew />}
          />

          <Route
            path={ERouteNames.GreatRhino}
            element={<GreatRhinoLanding />}
          />

          <Route
            path={ERouteNames.LandingRouletteBlack}
            element={<LandingRouletteBlack />}
          />

          <Route path={ERouteNames.Tesla} element={<LandingTesla />} />

          <Route path={ERouteNames.OlympLanding} element={<LandingOlymp />} />

          <Route
            path={ERouteNames.MoneyComingLanding}
            element={<LandingMoneyComing />}
          />
          {/* Utils */}
          <Route
            path={ERouteNames.DevLanguages}
            element={
              <Suspense fallback={<h1>UTILS Loading…</h1>}>
                <WebConfigurations />
              </Suspense>
            }
          />

          <Route
            path="*"
            element={<Navigate to={ERouteNames.CasinoHome} replace />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default InitialRoutes;
