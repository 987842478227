import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createPortal } from 'react-dom';
import { HOCOutsideClick, Text } from 'decryption_component-kit';
import { useAuth } from 'decryption_protocol_hooks';

import ComponentKitLogo from 'components/ui/KitComponents/ComponentKitLogo';
import Wheel from './components/Wheel';
import BonusContent from './components/BonusContent';
import RegistrationModal from './components/RegistrationModal';

import { BONUSES } from './constnats';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { ERouteNames } from 'Routes/types';

import styles from './LandingOlymp.module.css';

const LandingOlymp = () => {
  const [isModal, setModal] = useState(false);

  const [isChooseBonusModal, setModalBonusState] = useState(false);

  const [activeBonusId, setActiveBonusId] = useState(1);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAuth = useAuth({ isConnectedApp });

  const navigate = useNavigate();

  const toggleChooseBonusModal = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setModalBonusState(!isChooseBonusModal);
  };

  const callback = () => {
    setModal(true);
  };

  const handleSetNewBonus = (id: number) => () => {
    setActiveBonusId(id);
    setModalBonusState(false);
  };

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    navigate(ERouteNames.Home);
  }, [isAuth]);

  return (
    <div
      style={{
        background:
          'url(/icons-elon/landings/olymp/bg.b3c3680c.png)  no-repeat 50%/cover',
      }}
      className={styles.wrapperLandingOlymp}
    >
      <div className={styles.header}>
        <ComponentKitLogo customIsMobile={false} classes={styles.logoSize} />

        <div className={styles.title}>
          <div>
            <Text idT="olimpNew.titleOne" />
          </div>
          <div>
            <Text idT="olimpNew.titleTwo" />
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <Wheel callback={callback} />

        <div
          className={styles.gold}
          style={{
            background:
              'url(/icons-elon/landings/olymp/gold.png) no-repeat 50% / cover',
          }}
        />
      </div>

      {isModal &&
        createPortal(
          <RegistrationModal
            openBonusModal={toggleChooseBonusModal}
            // @ts-ignore
            bonusInfo={BONUSES[activeBonusId]}
          />,
          document.body
        )}

      {isChooseBonusModal &&
        createPortal(
          <HOCOutsideClick
            classes={styles.chooseBonuses}
            handleFunc={toggleChooseBonusModal}
          >
            <>
              {Object.values(BONUSES).map((b) => (
                <BonusContent
                  setActiveBonusId={handleSetNewBonus(b.id)}
                  key={b.id}
                  {...b}
                  isActive={b.id === activeBonusId}
                />
              ))}
            </>
          </HOCOutsideClick>,
          document.body
        )}
    </div>
  );
};

export default LandingOlymp;
