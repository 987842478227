import React, { useEffect, useState } from 'react';
import { HOCOutsideClick } from 'decryption_component-kit';

import InputBox from '../InputBox';
import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import {
  getActiveCurrencyIdSelector,
  setActiveCurrencyIdAction,
} from 'stores/forms/signUp/selectors';

import styles from './CurrencyBox.module.css';

type Props = any;

type InputData = {
  currencyIcon: string;
  input: string;
};
const INPUT_DATA: { [key: number]: InputData } = {
  50: {
    currencyIcon: '/icons-elon/landings/olymp/bdt.svg',
    input: 'Bangladeshi Taka (BDT)',
  },
  643: {
    currencyIcon: '/icons-elon/landings/olymp/rub.svg',
    input: 'Russian Ruble (RUB)',
  },
  860: {
    currencyIcon: '/icons-elon/landings/olymp/uzs.svg',
    input: 'Uzbekistani sum (UZS)',
  },
  398: {
    currencyIcon: '/icons-elon/landings/olymp/kzt.svg',
    input: 'Kazakhstan tenge (KZT) ',
  },
  417: {
    currencyIcon: '/icons-elon/landings/olymp/kgs.svg',
    input: 'Kyrgyzstani som (KGS)',
  },
};

const PasswordBox = (props: Props) => {
  const [isOpen, setOpenList] = useState(false);

  const [inputData, setInputData] = useState<InputData | null>(null);

  const currencyId = useZustandRegistrationStore(getActiveCurrencyIdSelector);

  const setCurrencyId = useZustandRegistrationStore(setActiveCurrencyIdAction);

  const handleChangeInput = (i: InputData) => () => {
    setInputData(i);
  };

  const handleOpenList = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    // setOpenList(!isOpen);
  };

  useEffect(() => {
    if (!currencyId) {
      setCurrencyId(50);
    }
  }, [currencyId]);

  useEffect(() => {
    if (!currencyId) {
      return;
    }
    const data = INPUT_DATA[currencyId];

    if (!data) {
      return;
    }

    setInputData(data);
  }, [currencyId]);

  return (
    <div onClick={handleOpenList} className={styles.wrapperSelector}>
      {inputData && (
        <InputBox
          leftImg={inputData.currencyIcon}
          rightImg=""
          // /icons-elon/landings/olymp/arrow.svg
          setInput={console.info}
          input={{ value: inputData.input, errorText: '' }}
          disabled
          label="Currency"
        />
      )}

      {isOpen && (
        <HOCOutsideClick
          classes={styles.wrapperItems}
          handleFunc={handleOpenList}
        >
          <>
            {Object.values(INPUT_DATA).map((i) => (
              <div
                key={i.input}
                className={styles.item}
                onClick={handleChangeInput(i)}
              >
                <img src={i.currencyIcon} />
                <div className={styles.name}>{i.input}</div>
              </div>
            ))}
          </>
        </HOCOutsideClick>
      )}
    </div>
  );
};

export default PasswordBox;
