import { ESports } from 'decryption_protocol/dist/lib/store/betting/entities/sports/types';
import { EAppType } from 'decryption_component-kit/dist/lib/types/ui';
import {
  JabiInHouseSvg,
  ElonHouseSvg,
  FSWinHouseSvg,
  // Loyalty
  BronzeSvg,
  SilverSvg,
  DiamondSvg,
  GoldSvg,
  GreenSvg,
  PlatinumSvg,
  PlatinumOneSvg,
  // Flags
  AzeSvg,
  BnSvg,
  BsSvg,
  ChSvg,
  DeSvg,
  EnSvg,
  EsSvg,
  FrSvg,
  HiSvg,
  ItSvg,
  KoSvg,
  KzSvg,
  NoSvg,
  PtSvg,
  RoSvg,
  RuSvg,
  TrSvg,
  UkrSvg,
  UzSvg,
  // BETTING
  AmericanFootballSvg,
  AussieRulesSvg,
  BadmintonSvg,
  BaseballSvg,
  BasketballSvg,
  Basketball3x3Svg,
  BeachSoccerSvg,
  BoxingSvg,
  ChessSvg,
  CricketSvg,
  DartsSvg,
  EFifaSvg,
  ENbaSvg,
  ENhlSvg,
  EsportSvg,
  FieldHockeySvg,
  FutsalSvg,
  IceHockeySvg,
  KabaddiSvg,
  MmaSvg,
  RugbySvg,
  SnookerSvg,
  SoccerSvg,
  SquashSvg,
  TableTennisSvg,
  TennisSvg,
  VolleyballSvg,
} from 'decryption_component-kit';

import { getProviderName } from './shared';

import { APP_TYPE } from 'constants/ui';

export const getFlagIcon = (lang: string, classes?: string) => {
  switch (lang) {
    case 'pt':
      return <PtSvg classes={classes} />;
    case 'ro':
      return <RoSvg classes={classes} />;
    case 'fr':
      return <FrSvg classes={classes} />;
    case 'bs':
      return <BsSvg classes={classes} />;
    case 'no':
      return <NoSvg classes={classes} />;
    case 'en':
      return <EnSvg classes={classes} />;
    case 'ru':
      return <RuSvg classes={classes} />;
    case 'hi':
      return <HiSvg classes={classes} />;
    case 'es':
      return <EsSvg classes={classes} />;
    case 'tr':
      return <TrSvg classes={classes} />;
    case 'ukr':
      return <UkrSvg classes={classes} />;
    case 'de':
      return <DeSvg classes={classes} />;
    case 'az':
      return <AzeSvg classes={classes} />;
    case 'kz':
      return <KzSvg classes={classes} />;
    case 'bn':
      return <BnSvg classes={classes} />;
    case 'uz':
      return <UzSvg classes={classes} />;
    case 'it':
      return <ItSvg classes={classes} />;

    default:
      break;
  }
};

export const getSportIcon = (sportId: number) => {
  switch (sportId) {
    case ESports.Soccer:
    case ESports.HandBall: // handball
      return <SoccerSvg />;
    case ESports.Tennis:
      return <TennisSvg />;
    case ESports.Cricket:
      return <CricketSvg />;
    case ESports.IceHockey:
      return <IceHockeySvg />;
    case ESports.Basketball:
      return <BasketballSvg />;
    case ESports.TableTennis:
      return <TableTennisSvg />;
    case ESports.Volleyball:
    case ESports.BeachVolleyball:
      return <VolleyballSvg />;
    case ESports.CyberSports:
      return <EsportSvg />;
    case ESports.Fifa:
      return <EFifaSvg />;
    case ESports.ENHL:
      return <ENhlSvg />;
    case ESports.ENBA:
      return <ENbaSvg />;
    case ESports.Basketbal3X3:
      return <Basketball3x3Svg />;
    //
    case ESports.Futsal:
      return <FutsalSvg />;
    case ESports.Snooker:
      return <SnookerSvg />;
    case ESports.Badminton:
      return <BadmintonSvg />;
    case ESports.Squash:
      return <SquashSvg />;
    case ESports.Kabaddi:
      return <KabaddiSvg />;
    case ESports.Baseball:
      return <BaseballSvg />;
    case ESports.AmericanFootball:
      return <AmericanFootballSvg />;
    case ESports.AussieRules:
      return <AussieRulesSvg />;
    case ESports.Rugby:
      return <RugbySvg />;
    case ESports.BeachSoccer:
      return <BeachSoccerSvg />;
    case ESports.Darts:
      return <DartsSvg />;
    case ESports.Boxing:
      return <BoxingSvg />;
    case ESports.Waterpolo:
      return <VolleyballSvg />;
    case ESports.FieldHockey:
      return <FieldHockeySvg />;
    case ESports.Chess:
      return <ChessSvg />;
    case ESports.Pesapallo:
      return <VolleyballSvg />;
    case ESports.MMA:
      return <MmaSvg />;

    default:
      return <div>{sportId}</div>;
  }
};

export const getCasinoProviderIcon = (name: string, classes = '') => {
  try {
    const key = getProviderName(name);

    switch (key) {
      case '1x2gaming':
        return (
          <img src="/icons-elon/providers/1x2gaming.svg" className={classes} />
        );
      case 'advantplay':
        return (
          <img src="/icons-elon/providers/advantplay.svg" className={classes} />
        );
      case 'tada':
        return <img src="/icons-elon/providers/tada.svg" className={classes} />;
      case 'mrslotty':
        return (
          <img src="/icons-elon/providers/mrslotty.svg" className={classes} />
        );
      case 'bigtimegamingbranded':
        return (
          <img
            src="/icons-elon/providers/bigtimegamingbranded.svg"
            className={classes}
          />
        );
      case 'espressogames':
        return (
          <img
            src="/icons-elon/providers/espressogames.svg"
            className={classes}
          />
        );
      case 'gamevy':
      case 'gamevybranded':
        return (
          <img src="/icons-elon/providers/gamevy.svg" className={classes} />
        );
      case 'gamesglobal':
        return (
          <img
            src="/icons-elon/providers/gamesglobal.svg"
            className={classes}
          />
        );
      case 'revolvergaming':
        return (
          <img
            src="/icons-elon/providers/revolvergaming.svg"
            className={classes}
          />
        );
      case 'quickspin':
        return (
          <img src="/icons-elon/providers/quickspin.svg" className={classes} />
        );
      case 'skywindlive':
        return (
          <img
            src="/icons-elon/providers/skywindlive.svg"
            className={classes}
          />
        );
      case 'netent':
        return (
          <img src="/icons-elon/providers/netent.svg" className={classes} />
        );
      case 'ctgaming':
        return (
          <img src="/icons-elon/providers/ctgaming.svg" className={classes} />
        );
      case 'bigtimegaming':
        return (
          <img
            src="/icons-elon/providers/bigtimegaming.svg"
            className={classes}
          />
        );
      case 'betsolutions':
        return (
          <img
            src="/icons-elon/providers/betSolution.svg"
            className={classes}
          />
        );
      case 'redrake':
        return (
          <img src="/icons-elon/providers/redrake.svg" className={classes} />
        );
      case 'skywind':
        return (
          <img src="/icons-elon/providers/skywind.svg" className={classes} />
        );
      case 'wazdan':
        return (
          <img src="/icons-elon/providers/wazdan.svg" className={classes} />
        );
      case 'eurasiangaming':
        return (
          <img
            src="/icons-elon/providers/eurasiangaming.svg"
            className={classes}
          />
        );

      case 'betradar':
        return (
          <img src="/icons-elon/providers/betradar.svg" className={classes} />
        );
      case 'onetouch':
        return (
          <img src="/icons-elon/providers/onetouch.svg" className={classes} />
        );
      case 'betgames':
        return (
          <img src="/icons-elon/providers/betgames.svg" className={classes} />
        );
      case 'pushgaming':
        return (
          <img src="/icons-elon/providers/pushgaming.svg" className={classes} />
        );

      case 'high5':
        return (
          <img src="/icons-elon/providers/high5.svg" className={classes} />
        );
      case 'hollegames':
        return (
          <img src="/icons-elon/providers/holleGames.svg" className={classes} />
        );
      case 'igtech':
        return (
          <img src="/icons-elon/providers/igtech.svg" className={classes} />
        );
      case 'infingame':
        return (
          <img src="/icons-elon/providers/infingame.svg" className={classes} />
        );
      case 'jaderabbit':
        return (
          <img src="/icons-elon/providers/jaderabbit.svg" className={classes} />
        );
      case 'jdb':
        return <img src="/icons-elon/providers/jdb.svg" className={classes} />;
      case 'lucky':
        return (
          <img src="/icons-elon/providers/lucky.svg" className={classes} />
        );
      case 'groove':
        return (
          <img src="/icons-elon/providers/groove.svg" className={classes} />
        );
      case 'gamingcorps':
        return (
          <img
            src="/icons-elon/providers/gamingCorps.svg"
            className={classes}
          />
        );
      case '1spin4win':
        return (
          <img src="/icons-elon/providers/1spin4win.svg" className={classes} />
        );
      case 'absolutelylivegaming':
        return (
          <img
            src="/icons-elon/providers/absoluteLiveGaming.svg"
            className={classes}
          />
        );
      case 'bluebirdgaming':
        return (
          <img src="/icons-elon/providers/bluebird.svg" className={classes} />
        );
      case 'qtech':
        return (
          <img src="/icons-elon/providers/qtech.svg" className={classes} />
        );
      case '5men':
        return <img src="/icons-elon/providers/5men.svg" className={classes} />;
      case 'atmosfera':
        return (
          <img src="/icons-elon/providers/atmosfera.svg" className={classes} />
        );
      case 'avatarux':
        return (
          <img src="/icons-elon/providers/avatarUx.svg" className={classes} />
        );
      case 'beter.live':
        return (
          <img src="/icons-elon/providers/beterLive.svg" className={classes} />
        );
      case 'zillion':
        return (
          <img
            src="/icons-elon/providers/zillonGames.svg"
            className={classes}
          />
        );
      case 'spinza':
        return (
          <img src="/icons-elon/providers/spinza.svg" className={classes} />
        );
      case 'reevo':
        return (
          <img src="/icons-elon/providers/reevo.svg" className={classes} />
        );
      case 'play_n_go':
        return (
          <img src="/icons-elon/providers/playNGo.svg" className={classes} />
        );
      case 'playtech':
        return (
          <img src="/icons-elon/providers/playtech.svg" className={classes} />
        );
      case 'popiplay':
        return (
          <img src="/icons-elon/providers/popipay.svg" className={classes} />
        );

      case 'amatic':
        return (
          <img src="/icons-elon/providers/amatic.svg" className={classes} />
        );
      case 'amigogaming':
        return (
          <img
            src="/icons-elon/providers/amigoGaming.svg"
            className={classes}
          />
        );
      case 'amusnet':
        return (
          <img src="/icons-elon/providers/amusnet.svg" className={classes} />
        );
      case 'aviatrix':
        return (
          <img src="/icons-elon/providers/aviatrix.svg" className={classes} />
        );
      case 'bgaming':
        return (
          <img src="/icons-elon/providers/b-gaming.svg" className={classes} />
        );
      case 'belatragames':
        return (
          <img src="/icons-elon/providers/belatra.svg" className={classes} />
        );
      case 'betsoft':
        return (
          <img src="/icons-elon/providers/bet-soft.svg" className={classes} />
        );
      case 'blueprint':
        return (
          <img src="/icons-elon/providers/blueprint.svg" className={classes} />
        );
      //------ not
      case 'boominggames':
        return (
          <img
            src="/icons-elon/providers/booming-games.svg"
            className={classes}
          />
        );
      case 'caleta':
        return (
          <img src="/icons-elon/providers/caleta.svg" className={classes} />
        );
      case 'casinoTechnology':
        return (
          <img
            src="/icons-elon/providers/casinoTechnology.svg"
            className={classes}
          />
        );
      case 'charismatic':
        return (
          <img
            src="/icons-elon/providers/charismatic.svg"
            className={classes}
          />
        );
      case 'conceptgaming':
        return (
          <img
            src="/icons-elon/providers/concept-gaming.svg"
            className={classes}
          />
        );
      case 'elbet':
        return (
          <img src="/icons-elon/providers/el-bet.svg" className={classes} />
        );
      case 'elysiumstudios':
        return (
          <img
            src="/icons-elon/providers/elysiumStudios.svg"
            className={classes}
          />
        );
      case 'endorphina':
        return (
          <img src="/icons-elon/providers/endorfina.svg" className={classes} />
        );
      case 'eurasiangamingbingo':
        return (
          <img
            src="/icons-elon/providers/eurasian-gaming-bingo.svg"
            className={classes}
          />
        );
      // Vae
      case 'eurasiangamingslots':
        return (
          <img
            src="/icons-elon/providers/eurasian-gaming-slots.svg"
            className={classes}
          />
        );
      case 'evolution':
        return (
          <img src="/icons-elon/providers/evolution.svg" className={classes} />
        );
      case 'evoplay':
        return (
          <img src="/icons-elon/providers/evoplay.svg" className={classes} />
        );
      case 'ezugi':
        return (
          <img src="/icons-elon/providers/ezugi.svg" className={classes} />
        );
      case 'fachai':
        return (
          <img src="/icons-elon/providers/fachai.svg" className={classes} />
        );
      case 'fantasma':
        return (
          <img src="/icons-elon/providers/fantasma.svg" className={classes} />
        );
      case 'fastspin':
        return (
          <img src="/icons-elon/providers/fastspin.svg" className={classes} />
        );
      case 'fazi':
        return <img src="/icons-elon/providers/fazi.svg" className={classes} />;
      case 'gamanza':
        return (
          <img src="/icons-elon/providers/gamanza.svg" className={classes} />
        );
      case 'gameart':
        return (
          <img src="/icons-elon/providers/game-art.svg" className={classes} />
        );
      case 'gamebeat':
        return (
          <img src="/icons-elon/providers/game-bet.svg" className={classes} />
        );
      case 'gamzix':
        return (
          <img src="/icons-elon/providers/gamzix.svg" className={classes} />
        );
      case 'goldenrace':
        return (
          <img src="/icons-elon/providers/goldenRace.svg" className={classes} />
        );
      case 'habanero':
        return (
          <img src="/icons-elon/providers/habenero.svg" className={classes} />
        );
      case 'hacksawgaming':
        return (
          <img
            src="/icons-elon/providers/hacksawgaming.svg"
            className={classes}
          />
        );
      // aoskd
      case 'hogaming':
        return <img src="/icons-elon/providers/hg.svg" className={classes} />;
      case 'igrosoft':
        return (
          <img src="/icons-elon/providers/igrosoft.svg" className={classes} />
        );
      case 'igrsoft':
        return (
          <img src="/icons-elon/providers/igrsoft.svg" className={classes} />
        );
      case 'jetgames':
        return (
          <img src="/icons-elon/providers/jetGames.svg" className={classes} />
        );
      case 'jili':
        return <img src="/icons-elon/providers/jili.svg" className={classes} />;
      case 'kagaming':
        return (
          <img src="/icons-elon/providers/kaGaming.svg" className={classes} />
        );
      case 'kalamba':
        return (
          <img src="/icons-elon/providers/kalamba.svg" className={classes} />
        );
      // /asld/
      case 'leapgamingvirtualsports':
        return (
          <img
            src="/icons-elon/providers/leap-gaming-virtual-sports.svg"
            className={classes}
          />
        );
      case 'leapgaming':
        return (
          <img
            src="/icons-elon/providers/leap-gaming.svg"
            className={classes}
          />
        );
      case 'lottoinstantwin':
        return (
          <img
            src="/icons-elon/providers/lotto-instan.svg"
            className={classes}
          />
        );
      case 'macawgaming':
        return (
          <img
            src="/icons-elon/providers/macawGaming.svg"
            className={classes}
          />
        );
      case 'mancala':
        return (
          <img src="/icons-elon/providers/mancala.svg" className={classes} />
        );
      case 'mascot':
        return (
          <img
            src="/icons-elon/providers/mascot-gaming.svg"
            className={classes}
          />
        );
      case 'microgamingbranded':
        return (
          <img
            src="/icons-elon/providers/microgaming-branded.svg"
            className={classes}
          />
        );
      case 'microgaming':
        return (
          <img
            src="/icons-elon/providers/microgaming.svg"
            className={classes}
          />
        );
      case 'mplay':
        return (
          <img src="/icons-elon/providers/mplay.svg" className={classes} />
        );
      case 'net-ent-table':
        return (
          <img
            src="/icons-elon/providers/net-ent-table.svg"
            className={classes}
          />
        );
      case 'netgame':
        return (
          <img src="/icons-elon/providers/net-game.svg" className={classes} />
        );
      case 'netgaming':
      case 'netgamingbranded':
        return (
          <img src="/icons-elon/providers/net-gaming.svg" className={classes} />
        );
      case 'netentbranded':
        return (
          <img
            src="/icons-elon/providers/net-tent-branded.svg"
            className={classes}
          />
        );
      case 'netentstandard':
        return (
          <img src="/icons-elon/providers/netent-s.svg" className={classes} />
        );
      case 'nolimitcity':
        return (
          <img
            src="/icons-elon/providers/no-limit-city.svg"
            className={classes}
          />
        );
      case 'nucleus':
        return (
          <img src="/icons-elon/providers/nucleus.svg" className={classes} />
        );
      case 'onlyplay':
        return (
          <img src="/icons-elon/providers/onlyplay.svg" className={classes} />
        );
      case 'pgsoft':
        return (
          <img src="/icons-elon/providers/pgsoft.svg" className={classes} />
        );
      case 'platipus':
        return (
          <img src="/icons-elon/providers/platipus.svg" className={classes} />
        );
      case 'playsonpremium':
        return (
          <img
            src="/icons-elon/providers/playson-premium.svg"
            className={classes}
          />
        );
      case 'playson':
        return (
          <img src="/icons-elon/providers/playson.svg" className={classes} />
        );
      // asd
      case 'pragmaticplaybranded':
        return (
          <img
            src="/icons-elon/providers/progmatic-play-branded.svg"
            className={classes}
          />
        );
      case 'pragmaticplaylive':
        return (
          <img
            src="/icons-elon/providers/progmatic-play-live.svg"
            className={classes}
          />
        );
      case 'pragmaticplay':
        return (
          <img
            src="/icons-elon/providers/progmatic-play.svg"
            className={classes}
          />
        );
      case 'redtigerpremium':
        return (
          <img
            src="/icons-elon/providers/red-tiger-premium.svg"
            className={classes}
          />
        );
      case 'redtigerslots':
      case 'redtigerslot':
      case 'redtiger':
        return (
          <img
            src="/icons-elon/providers/red-tiger-slots.svg"
            className={classes}
          />
        );
      case 'relaxgaming':
        return (
          <img src="/icons-elon/providers/relax.svg" className={classes} />
        );
      case 'retrogaming':
        return (
          <img src="/icons-elon/providers/retro.svg" className={classes} />
        );
      case 'rtgslots':
        return (
          <img src="/icons-elon/providers/rtg-slots.svg" className={classes} />
        );
      case 'skilrock':
        return (
          <img src="/icons-elon/providers/skilrock.svg" className={classes} />
        );
      case 'slotmill':
        return (
          <img src="/icons-elon/providers/slotmill.svg" className={classes} />
        );
      case 'smartsoft':
        return (
          <img src="/icons-elon/providers/smartSoft.svg" className={classes} />
        );
      case 'spadegaming':
        return (
          <img
            src="/icons-elon/providers/spadegaming.svg"
            className={classes}
          />
        );
      case 'spearheadstudios':
        return (
          <img src="/icons-elon/providers/spearhead.svg" className={classes} />
        );
      case 'spiffbetgames':
        return (
          <img src="/icons-elon/providers/spifbet.svg" className={classes} />
        );
      case 'spinmatic':
        return (
          <img src="/icons-elon/providers/spinmatic.svg" className={classes} />
        );
      case 'spinomenal':
        return (
          <img src="/icons-elon/providers/spinomenal.svg" className={classes} />
        );
      case 'spribe':
        return (
          <img src="/icons-elon/providers/spribe.svg" className={classes} />
        );
      case 'superlottofast':
        return (
          <img
            src="/icons-elon/providers/super-lotto-fast.svg"
            className={classes}
          />
        );
      case 'superlottoslots':
      case 'superlotto':
        return (
          <img
            src="/icons-elon/providers/super-lotto-slots.svg"
            className={classes}
          />
        );
      case 'superlottotv':
        return (
          <img
            src="/icons-elon/providers/superlottoTV.svg"
            className={classes}
          />
        );
      case 'superspadegames':
        return (
          <img src="/icons-elon/providers/superSpade.svg" className={classes} />
        );
      case 'swintt':
        return (
          <img src="/icons-elon/providers/swintt.svg" className={classes} />
        );
      case 'swinttpremium':
        return (
          <img src="/icons-elon/providers/swintt.svg" className={classes} />
        );
      case 'threeoakspremium':
        return (
          <img
            src="/icons-elon/providers/three-oaks-premium.svg"
            className={classes}
          />
        );
      case '3oaks':
        return (
          <img src="/icons-elon/providers/three-oaks.svg" className={classes} />
        );
      case 'thunderkick':
        return (
          <img
            src="/icons-elon/providers/thunderkick.svg"
            className={classes}
          />
        );
      case 'tomhorn':
        return (
          <img src="/icons-elon/providers/tomhorn.svg" className={classes} />
        );
      case 'triplecherry':
        return (
          <img
            src="/icons-elon/providers/triple-cherry.svg"
            className={classes}
          />
        );
      case 'tripleprofitsgames':
        return (
          <img
            src="/icons-elon/providers/triple-profits-games.svg"
            className={classes}
          />
        );
      case 'truelab':
        return (
          <img src="/icons-elon/providers/truelab.svg" className={classes} />
        );
      case 'turbogames':
        return (
          <img
            src="/icons-elon/providers/turbo-games.svg"
            className={classes}
          />
        );
      case 'vibragaming':
        return (
          <img
            src="/icons-elon/providers/vibragaming.svg"
            className={classes}
          />
        );
      case 'vibragamingpremium':
        return (
          <img
            src="/icons-elon/providers/vibragamingpremium.svg"
            className={classes}
          />
        );
      case 'vivogaming':
        return (
          <img src="/icons-elon/providers/vivogaming.svg" className={classes} />
        );
      case 'xprogaming':
        return (
          <img src="/icons-elon/providers/XProgaming.svg" className={classes} />
        );
      case 'yggdrasil':
        return (
          <img src="/icons-elon/providers/yggdasil.svg" className={classes} />
        );
      case 'zeusplay':
        return (
          <img src="/icons-elon/providers/zeusplay.svg" className={classes} />
        );

      default:
        return <div className={classes}>{key}</div>;
    }
  } catch (error) {
    console.error('_____ ERROR WITH PROVIDER ICONS', name, error);
  }
};

export const getVipClubIcon = (level: number, classes?: string) => {
  //   GreenSvg
  // BronzeSvg
  // DiamonSvg
  // PlatinumOneSvg
  // PlatinumSvg
  // GoldSvg
  switch (level) {
    case 0:
      return <GreenSvg classes={classes} />;
    case 1:
      return <BronzeSvg classes={classes} />;
    case 2:
      return <SilverSvg classes={classes} />;
    case 3:
      return <GoldSvg classes={classes} />;
    case 4:
    case 5:
    case 6:
      return <PlatinumOneSvg classes={classes} />;
    case 7:
    case 8:
    case 9:
      return <PlatinumSvg classes={classes} />;
    case 10:
      <DiamondSvg classes={classes} />;
      break;

    default:
      return <DiamondSvg classes={classes} />;
  }
};

export const getCasinoInHouseIcon = () => {
  switch (APP_TYPE) {
    case EAppType.Elon:
      return ElonHouseSvg;
    case EAppType.FsWin:
      return FSWinHouseSvg;
    case EAppType.JabiBet:
      return JabiInHouseSvg;

    default:
      return () => <svg />;
  }
};
