import React, { useState } from 'react';

import InputBox from '../InputBox';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { composeValidations, isPassword } from 'utils/validation';

type Props = {
  onChange: (v: string) => void;
};

const PasswordBox = ({ onChange }: Props) => {
  const [psw, setPsw] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [type, setType] = useState('password');

  const toggleType = () => {
    if (type === 'password') {
      setType('string');

      return;
    }

    setType('password');
  };

  const handleChangePsw = (data: DefaultInputType) => {
    const { value } = data;

    console.log('🚀 ~ handleChangePsw ~ data:', data);

    const errorText =
      composeValidations([
        isPassword({
          value,
          errorText: ['errors.passwordMin', 'errors.passwordMax'],
        }),
      ]) || '';

    setPsw({ value, errorText });

    if (!errorText) {
      onChange(value);

      return;
    }

    onChange('');
  };

  return (
    <InputBox
      leftImg="/icons-elon/landings/olymp/key.svg"
      rightImg="/icons-elon/landings/olymp/eye.svg"
      setInput={handleChangePsw}
      input={psw}
      handleRight={toggleType}
      label="Password"
      type={type}
    />
  );
};

export default PasswordBox;
