export const BONUSES = {
  1: {
    text: 'Casino welcome bonus',
    bonus: '150000 KZT + 250 spins',
    img: '/icons-elon/landings/olymp/welcome.png',
    id: 1,
  },
  2: {
    text: 'Welcom bonus on sport',
    bonus: '30000',
    img: '/icons-elon/landings/olymp/box-blue.png',
    id: 2,
  },
  3: {
    text: 'play without bonus',
    bonus: '',
    img: '/icons-elon/landings/olymp/info.svg',
    id: 3,
  },
};
