import React from 'react';
import cln from 'classnames';

import styles from './BonusContent.module.css';

type Props = {
  text?: string;
  bonus: string;
  isActive?: boolean;
  img: string;
  toggleChooseBonusModal?: (e: any) => void;
  setActiveBonusId?: () => void;
};

const BonusContent = ({
  text,
  bonus,
  img,
  toggleChooseBonusModal,
  isActive,
  setActiveBonusId,
}: Props) => (
  <div
    className={cln(styles.labelContent, { [styles.active]: isActive })}
    onClick={setActiveBonusId}
  >
    <div className={styles.left}>
      <div className={styles.welcomeItems}>
        <img src={img} />

        <div className={styles.text}>
          {text}
          <span className={styles.textBonus}>{bonus}</span>
        </div>
      </div>
    </div>

    {toggleChooseBonusModal && (
      <div className={styles.arrowWrapper} onClick={toggleChooseBonusModal}>
        <img src="/icons-elon/landings/olymp/arrow.svg" alt="" />
      </div>
    )}
  </div>
);

export default BonusContent;
