import React, { useRef } from 'react';

import { ERouteNames } from 'Routes/types';
import { useNavigate } from 'react-router';

import RowHeader from './components/RowHeader';
import RowGames from './components/RowGames';

import styles from './CasinoCategoryRow.module.css';

type Props = {
  categoryId: number;
  subCategoryId?: number | null;
  name: string;
};

const CategoryRow = ({ categoryId, subCategoryId = null, name }: Props) => {
  const wrapper = useRef<null | HTMLDivElement>(null);

  const navigate = useNavigate();

  const jumpTo = () => {
    const path = ERouteNames.CasinoCategories.replaceAll(
      ':categoryId',
      `${categoryId}`
    )
      .replaceAll(':subCategoryId', `${subCategoryId || 'all'}`)
      .replaceAll(':page', '1')
      .replaceAll(':scroll', '0');

    navigate(path);
  };

  return (
    <div className={styles.marginB} ref={wrapper}>
      <RowHeader iconId={categoryId} categoryName={name} jumpTo={jumpTo} />

      <RowGames categoryId={categoryId} subCategoryId={subCategoryId} />
    </div>
  );
};

export default CategoryRow;
