import { ZustandConfigurationStore } from './types';

export const getWebConfigurationSelector = (state: ZustandConfigurationStore) =>
  state.webConfiguration;

export const getColorsSelector = (state: ZustandConfigurationStore) =>
  state.colors;

export const getVersionSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.version;

export const getHomeBannersSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.banners || [];

export const getBonusCardsSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.bonusImagePaths;

export const getMobileAppsInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.apps;

export const getSocketTransportInfoSelector = (
  state: ZustandConfigurationStore
) => getWebConfigurationSelector(state)?.transport;

export const getBaseSocketUrlSelector = (state: ZustandConfigurationStore) =>
  getSocketTransportInfoSelector(state)?.address;

export const getBaseSocketForBettingFrameSelector = (
  state: ZustandConfigurationStore
) =>
  getSocketTransportInfoSelector(state)?.address?.replace('/casino', '') || '';

export const getSupportInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.support;

export const getRegistrationInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.registration;

export const getSupportMailSelector = (state: ZustandConfigurationStore) =>
  getSupportInfoSelector(state)?.mail;

export const getLanguagesForSelectorsSelector = (
  state: ZustandConfigurationStore
) =>
  getLanguagesInfoSelector(state)?.map((l, inx) => ({
    label: l.shortName,
    value: +inx,
  })) || [];

export const getDocsSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.docs;

export const getSocialInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.social;

export const getLanguagesInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.languages;

export const getKYCLinkSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.KYCLink;

export const getPartnersLinkSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.partnersUrl;

export const getSpecTranslateKeysSelector = (
  state: ZustandConfigurationStore
) => getWebConfigurationSelector(state)?.translateSpecialKeys;

export const getBettingInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.betting;

export const getLicenseInfosSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.license;

export const isAvailableBettingSelector = (state: ZustandConfigurationStore) =>
  getBettingInfoSelector(state)?.isBetting;

export const isFrameBettingSelector = (state: ZustandConfigurationStore) =>
  getBettingInfoSelector(state)?.isFrame;

export const getBettingFrameUrlSelector = (state: ZustandConfigurationStore) =>
  getBettingInfoSelector(state)?.frameDomain;

export const getAdditionalCategoryInfoSelector = (
  state: ZustandConfigurationStore
) => {
  const config = getWebConfigurationSelector(state);

  return config?.categories || null;
};

// ==================================== ACTIONS ====================================

export const getWebConfigurationAction = (state: ZustandConfigurationStore) =>
  state.getWebConfiguration;
