import React from 'react';

import styles from './Code.module.css';

type Props = {
  code: string;
  isArrow?: boolean;
  left?: string;
};

const Code = ({ code, isArrow, left = '0' }: Props) => (
  <div className={styles.drop}>
    <div className={styles.selection}>
      <div
        className={styles.flag}
        style={{
          background: 'url(/icons-elon/landings/olymp/flag-sprite.png)',
          backgroundPosition: `${left}px 0`,
          backgroundSize: '5630px 15px',
        }}
      />
      <div className={styles.code}> {code}</div>

      {isArrow && <div className={styles.arrow}>▼ </div>}
    </div>
  </div>
);

export default Code;
