import React, { useState } from 'react';
import cln from 'classnames';
import { HOCOutsideClick } from 'decryption_component-kit';

import Code from './components/Code';
import { PHONE_BOX } from './constants';

import styles from './PhoneBox.module.css';
import { DefaultInputType } from 'components/ui/Input/Base/types';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { MIN_PHONE_LENGTH } from 'utils/validation';

type Props = { onChange: (v: string) => void };

const PhoneBox = ({ onChange }: Props) => {
  const [activePhoneBox, setPhoneBox] = useState(PHONE_BOX[1]);

  const [isDrop, setDropState] = useState(false);

  const [phone, setPhone] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const toggleCode = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDropState(!isDrop);
  };

  const setPhoneValue = ({ target }: any) => {
    const { value } = target;

    const errorText = value.length !== MIN_PHONE_LENGTH ? 't.length' : '';

    if (!errorText) {
      onChange(`${activePhoneBox.code}${value}`);
    } else {
      onChange('');
    }
    setPhone({
      value,
      errorText,
    });
  };

  const setNewPhoneBox = (i: any) => () => {
    setPhoneBox(i);
    setDropState(false);
    onChange('');
  };

  return (
    <div className={styles.wrapperPhoneBox}>
      <div onClick={toggleCode} style={{ height: '100%' }}>
        <Code code={activePhoneBox.code} isArrow left={activePhoneBox.flag} />
      </div>

      {isDrop && (
        <HOCOutsideClick classes={styles.phoneWrapper} handleFunc={toggleCode}>
          {Object.values(PHONE_BOX).map((b) => (
            <div
              className={styles.row}
              key={b.code}
              onClick={setNewPhoneBox(b)}
            >
              <Code code={b.code} left={b.flag} />

              <div>{b.name}</div>
            </div>
          ))}
        </HOCOutsideClick>
      )}

      <input
        className={cln(styles.input, {
          [styles.error]: phone.errorText,
        })}
        pattern="[0-9]"
        type="number"
        value={phone.value}
        onChange={setPhoneValue}
      />
    </div>
  );
};

export default PhoneBox;
